<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <template v-if="!isLoading">
                    <label>Which date do you want to work overtime?</label>
                    <div class="card-grid grid-col-md-2 grid-col-lg-3">
                        <div v-for="allowedOvertimeDate in allowedOvertimeDates" :key="allowedOvertimeDate" class="card-link-container">
                            <div class="card card-select card-body" @click="selectDate(allowedOvertimeDate)" :class="{'active': overtimeDateSelected === allowedOvertimeDate}">
                                {{ moment(allowedOvertimeDate).format('ddd Do MMM \'YY') }}
                            </div>
                        </div>
                    </div>
                </template>

                <loading-state
                    v-if="isLoading"
                    title="Loading allowed overtime days">
                </loading-state>

                <!-- Empty state -->
                <div class="card card-body"
                     v-if="allowedOvertimeDates.length === 0 && !isLoading">
                    <empty-state
                        class="pb-2"
                        small
                        title="There are currently no overtime slots available for you to book via the app"
                        subtitle="Check back later or contact your SSM to request overtime">
                    </empty-state>
                </div>
            </div>
            <template v-if="allowedOvertimeDates.length !== 0 && overtimeDateSelected && !isLoading" >
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Day End Time</label>
                        <input type="time" class="form-control" v-model="endTime"/>
                    </div>
                </div>
                <div class="col-md-8 offset-md-2">
                    <button v-if="!deleteRequestIsLoading" class="btn btn-success btn-lg btn-block mb-3 mt-3" @click="submitRequest()" :disabled="!enableSubmitButton">Submit request</button>
                    <button v-if="deleteRequestIsLoading" class="btn btn-success btn-lg btn-block mb-3 mt-3" @click="submitRequest()" disabled><animated-loading class="mr-2" size="sm"></animated-loading> Submitting request</button>
                </div>
            </template>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                endTime: null,
                isLoading: true,
                deleteRequestIsLoading: false,
            }
        },

        mounted() {
            this.endTime = this.HisToHiDateFormat(this.user.technician.day_end_time);
            this.loadAllowedOvertimeDates(this.user.technician.id).then(() =>{
                this.isLoading = false;
            });
        },

        computed: {
            ...mapGetters([
                "overtimeDateSelected",
                "allowedOvertimeDates",
                "user"
            ]),

            /** Enable the submit button */
            enableSubmitButton() {
                if (this.overtimeDateSelected && this.endTime) {
                    return true;
                }

                return true;
            }
        },

        methods: {
            ...mapActions([
                "addOvertime",
                "displayToast",
                "setOvertimeDateSelected",
                "loadAllowedOvertimeDates"
            ]),

            /** Select a date */
            selectDate(date) {
                this.setOvertimeDateSelected(date)
            },

            dataIsValid() {
                if(!this.overtimeDateSelected) {
                    this.displayToast({text: "Date field is required", type: 'error'});
                    return false;
                }
                return true;
            },

            /** Submit the request */
            submitRequest() {
                if(this.dataIsValid()) {
                    this.deleteRequestIsLoading = true;
                    this.addOvertime({
                        technicianId: this.user.technician.id,
                        date: this.overtimeDateSelected,
                        dayStartTime: this.HisToHiDateFormat(this.user.technician.day_start_time),
                        dayEndTime: this.endTime
                    }).then(() => {
                        this.$router.push({name: 'overtime', params: {overtimeRequested: true}});
                    }).catch((errors) => {
                        this.displayToast({
                            text: errors[0],
                            type: 'error'
                        });
                    }).then(() => {
                        this.deleteRequestIsLoading = false;
                    });
                }

            },

            HisToHiDateFormat(str) {
                let split = str.split(':');
                return split[0] + ":" + split[1];
            }
        }
    }
</script>

<style scoped>

</style>